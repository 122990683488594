import React, {useContext} from 'react';
import { Button, Card, CardContent, LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {SearchContext} from "../../lib/contexts";
import GenerateImage from "./GenerateImage";
const useStyles = makeStyles({
   twit: {
      marginTop:20,
      minWidth: 200
   },
   title: {
      fontSize: 14,
   },
   example: {
      fontSize: 12,
      textAlign: 'center',
   },
   twitButton: {
      textAlign: 'center',
      margin: '5px auto auto'
   },
   twitLink: {
      textDecoration: 'none'
   },
   loadingBar: {
      width:'80%',
      margin:'40px auto 30px'
   },
});

const ShareBox = () => {
   const classes = useStyles();
   const {
      state: {personality, searchResults, debounceQuery },
   } = useContext(SearchContext);
   
   if (!searchResults || !personality || !debounceQuery){
      return false
   }
   const tempNum = searchResults ? Math.floor(Math.random() * searchResults.length) : 0;
   const randTweet = searchResults ? searchResults[tempNum].from+": "+searchResults[tempNum].text : '';
   
   return(
<Card className={classes.twit}>
      <CardContent>
         <Typography className={classes.title} color="textSecondary" gutterBottom>
            Share AI {personality.name}'s Answer!
         </Typography>
         <GenerateImage imageUrl={personality.photo} question={debounceQuery} name={personality.name} answer={searchResults[0].text}/>

         <Typography className={classes.example} color="textSecondary" gutterBottom>
            {
               (searchResults && searchResults.length) ? <span data-testid="test-tweet">"{randTweet}"</span> : <LinearProgress color="secondary" className={classes.loadingBar} />
            }
         </Typography>
         <Typography variant="h5" component="h2" className={classes.twitButton}>
            <a target="_blank" rel="noopener noreferrer" className={classes.twitLink} href={"https://twitter.com/intent/tweet?url=https%3A%2F%2FPhoneLookup.io&via=ustinCameron&text="+encodeURIComponent(randTweet)+"%20%7C%20Ask%20The%20AI%20&hashtags=PhoneLookup%2C"+personality.name}>
               <Button variant="contained" color="secondary" type="button">Share</Button>
            </a>
         </Typography>
      </CardContent>
   </Card>
)
};
export default ShareBox;
