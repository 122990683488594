import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getResult = async (companyCountryPhone,query) => {
  if(!query || query.length !== 10 || !companyCountryPhone){
    return false
  }
  const { token, userID } = ''//useCurrentUser();
  try {
    const { data } = await axios.get(`${API_HOST}/search/carrier?q=${companyCountryPhone+query}`,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-phonelookup-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
