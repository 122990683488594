import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getTrends = async () => {
  const apiURL = API_HOST + '/trending';
  const { token, userID } = ''//useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-phonelookup-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
